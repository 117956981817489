import React from 'react'
import Badge1 from './badge1.png'
import Badge2 from './badge2.png'
import Badge3 from './badge3.png'
import Cropper from 'react-cropper';
import './App.css';
import "cropperjs/dist/cropper.css";
import { useEffect, useRef, useState } from 'react';

const urlCreator = window.URL || window.webkitURL;
function App() {
  const cropperRef = useRef(null),
    cropperRef2 = useRef(null),
    uploadFile = useRef(null);

  const [canvas, setCanvas] = useState(),
    [chooseBadge, setBadge] = useState(1),
    [originalPhoto, setOriginal] = useState(),
    [step, setStep] = useState(1)

  const chooseFile = (evt) => {
    if (evt.target.files.length === 0)
      return;
  
    const file = evt.target.files[0]
    const url = urlCreator.createObjectURL(file)

    console.log(file)
    setOriginal(url)
    setStep(2)

    evt.target.value = '';
  }

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
    
    cropper.getCroppedCanvas().toBlob((blob)=>{
      var imageUrl = urlCreator.createObjectURL(blob);

      setCanvas(imageUrl)
      setStep(3)
    })
    
  };

  const makeProfileImage = async () => {
        const canv = document.createElement('canvas') // cropper.getCroppedCanvas({width: 500,height: 500})
        canv.width = 500
        canv.height = 500

        const ctx = canv.getContext('2d')

        const croped = new Image()
        croped.src = canvas
        croped.onload = () => {

          ctx.drawImage(croped, 0, 0, 500, 500)
          const img = new Image()
          let offset = 0;
          switch (chooseBadge)
          {
            case 1:
              img.src = Badge1
              offset = 60;
              break;
            case 2:
              img.src = Badge2
              break;
            case 3: 
              img.src = Badge3
              break;
            default:
              return;
          }
          
          img.onload = (e) => {
            ctx.drawImage(img, 
              (canv.width / 2) - (img.width / 2),
              canv.height - img.height - offset)
            // console.log(canv.toDataURL());

            const link = document.createElement('a');
            link.download = 'download.png';
            link.href = canv.toDataURL();
            link.click();
          }
        }

  }


  return (
    <div className="App">
      <div className="header"><h1>Sakolraj 111<sup>st</sup> anniversary badge</h1></div>
      <div className={`step step1 ${step === 1 && '-show'}`}>
        <div className="upload-btn-wrapper">
          <button className="btn">เลือกรูปภาพ</button>
          <input type="file" name="myfile" onChange={chooseFile} accept="image/jpeg,image/png" />

          <div style={{display: 'flex', marginTop: 50}}>
            <img src={Badge1} style={{height: 40, marginRight: 15}} />
            <img src={Badge2} style={{height: 40, marginRight: 15}} />
            <img src={Badge3} style={{height: 40}} />
          </div>
        </div>
      </div>
      <div className={`step step2 ${step === 2 && '-show'}`}>
        <div className="wrapper">
          {originalPhoto && <Cropper
            className="cropper"
            src={originalPhoto}
            style={{ width: '100%', maxHeight: 500 }}
            background={false}
            // Cropper.js options
            aspectRatio={1}
            guides={true}
            autoCropArea={0.8}
            responsive={true}
            ref={cropperRef}
          />}
        </div>
        <button className="btn-crop" onClick={onCrop}>ใช้รูปนี้</button>
      </div>
      <div className={`step step3 ${step === 3 && '-show'}`}>
        <div  style={{width :400, height: 400, position: 'relative'}}>
          {/*canvas && <Cropper
            className="cropper"
            src={canvas}
            style={{ height: 500, width: 500 }}
            // Cropper.js options
            aspectRatio={1}
            guides={false}
            responsive={true}
            dragMode="none"
            autoCrop={true}
            autoCropArea={1}
            cropBoxMovable={false}
            cropBoxResizable={false}
            zoomable={false}
            zoomOnTouch={false}
            zoomOnWheel={false}
            ref={cropperRef2}
            highlight={false}
            ready={()=>{
              const imageElement = cropperRef2.current,cropper = imageElement.cropper
              cropper.setCropBoxData({
                left: 0,
                top: 0,
                width: 500,
                height: 500,
              })
            }}
          />*/}
          <img src={canvas} style={{width: '100%', height: '100%'}} />

          <img className="badge badge1" style={{display: chooseBadge === 1 ? 'block' : 'none'}} src={Badge1} />
          <img className="badge badge2" style={{display: chooseBadge === 2 ? 'block' : 'none'}} src={Badge2} />
          <img className="badge badge3" style={{display: chooseBadge === 3 ? 'block' : 'none'}} src={Badge3} />
        </div>
        <div className="badge-group">
          <button className="btn-badge" onClick={()=>setBadge(1)}>แบบที่ 1</button>
          <button className="btn-badge" onClick={()=>setBadge(2)}>แบบที่ 2</button>
          <button className="btn-badge" onClick={()=>setBadge(3)}>แบบที่ 3</button>
        </div>
        <br />
        <button  className="btn-save" onClick={makeProfileImage}>บันทึกรูปลงเครื่อง</button>
      </div>
      
    </div>
  );
}

export default App;
